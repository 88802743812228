.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.table-tiny th,
.table-tiny td {
  padding-left: .5em;
  padding-right: .5em;
}

button svg {
  width: 1.5em;
  height: 1.5em;
}

img.logo {
  vertical-align: middle;
  display: inline-block;
}

.marker {
  animation: ping 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-color: hsla(112, 100%, 40%, 0.5);
  border-radius: 50%;
}

.marker:hover {
  background-color: hsla(33, 100%, 50%, 0.7);
  z-index: 5;
}

.precision_marker {
  transform: scale(0.2);
  border-radius: 50%;
  background-color: hsla(0, 100%, 40%, 1);
}

.precision_marker:hover {
  transform: scale(0.2);
  border-radius: 50%;
  background-color: rgb(255, 234, 0);
  z-index: 5;
}

.alarm_fire {
  animation: ping 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  /* background-color: hsla(0, 100%, 40%, 0.5); */
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20stroke%3D%22red%22%20fill%3D%22red%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%201024%201024%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M834.1%20469.2A347.49%20347.49%200%200%200%20751.2%20354l-29.1-26.7a8.09%208.09%200%200%200-13%203.3l-13%2037.3c-8.1%2023.4-23%2047.3-44.1%2070.8-1.4%201.5-3%201.9-4.1%202-1.1.1-2.8-.1-4.3-1.5-1.4-1.2-2.1-3-2-4.8%203.7-60.2-14.3-128.1-53.7-202C555.3%20171%20510%20123.1%20453.4%2089.7l-41.3-24.3c-5.4-3.2-12.3%201-12%207.3l2.2%2048c1.5%2032.8-2.3%2061.8-11.3%2085.9-11%2029.5-26.8%2056.9-47%2081.5a295.64%20295.64%200%200%201-47.5%2046.1%20352.6%20352.6%200%200%200-100.3%20121.5A347.75%20347.75%200%200%200%20160%20610c0%2047.2%209.3%2092.9%2027.7%20136a349.4%20349.4%200%200%200%2075.5%20110.9c32.4%2032%2070%2057.2%20111.9%2074.7C418.5%20949.8%20464.5%20959%20512%20959s93.5-9.2%20136.9-27.3A348.6%20348.6%200%200%200%20760.8%20857c32.4-32%2057.8-69.4%2075.5-110.9a344.2%20344.2%200%200%200%2027.7-136c0-48.8-10-96.2-29.9-140.9z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  /* border-radius: 50%; */
  background-size: cover;
  background-repeat: no-repeat
}

.alarm_fire:hover {
  z-index: 5;
  animation: 0;
}

.alarm_emergency {
  animation: ping 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  /* background-color: hsla(0, 100%, 40%, 0.5); */
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20stroke%3D%22red%22%20fill%3D%22red%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%2024%2024%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0z%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M20.79%209.23l-2-3.46L14%208.54V3h-4v5.54L5.21%205.77l-2%203.46L8%2012l-4.79%202.77%202%203.46L10%2015.46V21h4v-5.54l4.79%202.77%202-3.46L16%2012z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  /* border-radius: 50%; */
  background-size: cover;
  background-repeat: no-repeat
}

.alarm_emergency:hover {
  z-index: 5;
  animation: 0;
}

.alarm_intruder {
  animation: ping 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  /* background-color: hsla(0, 100%, 40%, 0.5); */
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22red%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%20320%20512%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M208%2096c26.5%200%2048-21.5%2048-48S234.5%200%20208%200s-48%2021.5-48%2048%2021.5%2048%2048%2048zm94.5%20149.1l-23.3-11.8-9.7-29.4c-14.7-44.6-55.7-75.8-102.2-75.9-36-.1-55.9%2010.1-93.3%2025.2-21.6%208.7-39.3%2025.2-49.7%2046.2L17.6%20213c-7.8%2015.8-1.5%2035%2014.2%2042.9%2015.6%207.9%2034.6%201.5%2042.5-14.3L81%20228c3.5-7%209.3-12.5%2016.5-15.4l26.8-10.8-15.2%2060.7c-5.2%2020.8.4%2042.9%2014.9%2058.8l59.9%2065.4c7.2%207.9%2012.3%2017.4%2014.9%2027.7l18.3%2073.3c4.3%2017.1%2021.7%2027.6%2038.8%2023.3%2017.1-4.3%2027.6-21.7%2023.3-38.8l-22.2-89c-2.6-10.3-7.7-19.9-14.9-27.7l-45.5-49.7%2017.2-68.7%205.5%2016.5c5.3%2016.1%2016.7%2029.4%2031.7%2037l23.3%2011.8c15.6%207.9%2034.6%201.5%2042.5-14.3%207.7-15.7%201.4-35.1-14.3-43zM73.6%20385.8c-3.2%208.1-8%2015.4-14.2%2021.5l-50%2050.1c-12.5%2012.5-12.5%2032.8%200%2045.3s32.7%2012.5%2045.2%200l59.4-59.4c6.1-6.1%2010.9-13.4%2014.2-21.5l13.5-33.8c-55.3-60.3-38.7-41.8-47.4-53.7l-20.7%2051.5z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  /* border-radius: 50%; */
  background-size: cover;
  background-repeat: no-repeat
}



.alarm_intruder:hover {
  /* background-color: hsla(0, 100%, 40%, 0.9); */
  z-index: 5;
  animation: 0;
}

.alarmbox svg {
  width: 1.5em;
  height: 1.5em;
}

.custom_fire_icon {
  animation: ping 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-image: url('./images/fire.png');
  background-size: cover;
  background-repeat: no-repeat
}

.custom_emergency_icon {
  animation: ping 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-image: url('./images/emergency-call.png');
  background-size: cover;
  background-repeat: no-repeat
}

.custom_intruder_icon {
  animation: ping 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-image: url('./images/bandit.png');
  background-size: cover;
  background-repeat: no-repeat
}

.custom_fire_icon:hover, .custom_emergency_icon:hover, .custom_intruder_icon:hover {
  z-index: 5;
  animation: 0;
}

@keyframes ping {
  0% {
    opacity: 0.1;
    transform: scale(0.1);
  }

  20% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

label.button {
  color: var(--chakra-colors-white);
  background-color: var(--chakra-colors-teal-500);
  display: table-cell;
  padding-left: 16px;
  padding-right: 16px;
  min-width: var(--chakra-sizes-10);
  height: var(--chakra-sizes-10);
  width: 100%;
  max-width: 200px;
  border-radius: 6px;
  text-align: center;
  vertical-align: middle;
  font-weight: var(--chakra-fontWeights-semibold);
  font-size: var(--chakra-fontSizes-md);
  transition: background-color 0.1s ease;
}

label.button:hover {
  background-color: var(--chakra-colors-gray-200);
  cursor: pointer;
}

label.button:active {
  background-color: var(--chakra-colors-gray-300);
  cursor: pointer;
}